/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                var currentAudio;
                $('i.audio[data-mp3]').click(function () {

                    if (currentAudio && !currentAudio.paused) {
                        currentAudio.pause();
                    }

                    currentAudio = new Audio($(this).data('mp3'));
                    currentAudio.play();

                });


                if($('i.audio.autoplay[data-mp3]:first')) {
                    setTimeout(function () {
                        var audio = new Audio($('i.autoplay.audio[data-mp3]:first').data('mp3'));
                        $(audio).on('ended', function () {
                            if (!$.data(audio, 'played')) {
                                $('i.audio[data-mp3]:eq(1)').click();
                                $.data(audio, 'played', true);
                            }
                        });
                        audio.play();
                        currentAudio = audio;
                    }, 2000);
                }


                $('body').on('click', 'input[type=radio]', function () {
                    $(this).parents('.question-item').next().find('i.audio[data-mp3]').click();
                });

                var $timer = $(".timer");
                if ($timer.length) {
                    var remaining = parseInt($timer.data('remaining'));
                    var end = Date.now() + remaining * 1000;

                    var intervalId = setInterval(function () {
                        if (Date.now() > end) {
                            $timer.text("Ended");
                            clearInterval(intervalId);
                            window.location.reload();
                        } else {
                            var remaining = Math.floor(end - Date.now()) / 1000;
                            var minutes = Math.floor(remaining / 60);
                            var seconds = Math.floor(remaining % 60);
                            minutes = minutes < 10 ? "0" + minutes : minutes;
                            seconds = seconds < 10 ? "0" + seconds : seconds;

                            $timer.text(minutes + ":" + seconds);
                        }
                    }, 1000);
                }

                $('body').on('change', '[name=package]', function () {
                    if ($(this).val()) {
                        $(this).siblings(".package-error").remove();
                    }
                });

                $('body').on('submit', '.register-form', function (e) {
                    e.preventDefault();
                    var $form = $(this);
                    var userLogin = $form.find('[name=user_email]').val();
                    if(userLogin.match(/[^a-zA-Z0-9@]/)) {
                        userLogin = 'U' + Date.now();
                    }
                    $form.find('[name=user_login]').val(userLogin);

                    var error = false;


                    $form.find('[name=display_name]').parent().find('div.error').remove();
                    if(!$form.find('[name=display_name]').val()) {
                        $form.find('[name=display_name]').before($('<div></div>').addClass('alert alert-danger error').text("Navn er påkrævet"));
                        error = true;
                    }

                    $form.find('[name=user_email]').parent().find('div.error').remove();
                    if(!$form.find('[name=user_email]').val()) {
                        $form.find('[name=user_email]').before($('<div></div>').addClass('alert alert-danger error').text("Email er påkrævet"));
                        error = true;
                    } else if(!$form.find('[name=user_email]').val().match(/^[^@\s]+@[^@]+$/)) {
                        $form.find('[name=user_email]').before($('<div></div>').addClass('alert alert-danger error').text("Ugyldig email"));
                        error = true;
                    }

                    $form.find('[name=password]').parent().find('div.error').remove();
                    if($form.find('[name=password]').val().length < 8) {
                        $form.find('[name=password]').before($('<div></div>').addClass('alert alert-danger error').text("Adgangskode skal være mindst 8 tegn."));
                        error = true;
                    }

                    $form.find('[name=repeat_password]').parent().find('div.error').remove();
                    if($form.find('[name=repeat_password]').val() !== $form.find('[name=password]').val()) {
                        $form.find('[name=repeat_password]').before($('<div></div>').addClass('alert alert-danger error').text("Adgangskode matcher ikke."));
                        error = true;
                    }

                    $form.find('[name=terms]').parent().parent().find('div.error').remove();
                    if(!$form.find('[name=terms]').is(':checked')) {
                        $form.find('[name=terms]').parent().before($('<div></div>').addClass('alert alert-danger error').text("Du skal acceptere vores betingelser."));
                        error = true;
                    }

                    if(!error) {
                        $form[0].submit();
                    }
                });

                $('body').on('submit', '.payment-form', function (e) {
                    e.preventDefault();
                    var $form = $(this);
                    $form.find('button').button('loading');
                    if ($form.find('[name=package]').val()) {
                        $form.find('[name=package]').siblings(".package-error").remove();
                        $.getJSON('/', $form.serialize(), function (data) {
                            $form.find('button').button('reset');
                            if (!data.error && $form.parents('.modal').length) {
                                $form.parents('.modal').modal('hide');
                            }
                            
                            if(data.error) {
                                $("<div></div>").addClass("package-error alert alert-danger").text(data.error).insertBefore($form.find('[name=package]'));
                            } else if (data.redirectUrl) {
                                window.location = data.redirectUrl;
                            } else {
                                var paymentWindow = new PaymentWindow(data);
                                paymentWindow.open();
                            }
                        });
                    } else {
                        $("<div></div>").addClass("package-error alert alert-danger").text('Vælg periode').insertBefore($form.find('[name=package]'));
                        $form.find('button').button('reset');
                    }
                    return false;
                });

                /*
                if (location.search.indexOf("new_user=yes") > 0) {
                    setTimeout(function(){
                        $('#modal-payment').modal("show");
                    }, 1000);
                }*/


                /* EDIT School */

                $('.collection-add').each(function () {
                    var prevDiv = $(this).prev();
                    var newInput = prevDiv.clone().removeClass('hidden').addClass("input-group");
                    newInput.find('input').val('');

                    $(this).data('tpl', newInput);
                });

                $('.collection-add').on('click', function (e) {
                    e.preventDefault();

                    var prevDiv = $(this).data('tpl');
                    var newInput = prevDiv.clone();
                    newInput.insertBefore(this);
                });

                $('body').on('click', '.collection-delete', function (e) {
                    e.preventDefault();
                    $(this).parent().parent().fadeOut(200, function () {
                        $(this).remove();
                    });
                });

                if(window.mapData && window.mapData.school) {
                    var mapData = window.mapData;
                    map = new google.maps.Map(document.getElementById("googlemap"), {
                        zoom: parseInt( 15 ),
                        center: new google.maps.LatLng( mapData.lat, mapData.lng),
                        mapTypeId: google.maps.MapTypeId.ROADMAP
                    });


                    var marker = new google.maps.Marker({
                        position: new google.maps.LatLng( mapData.lat, mapData.lng ),
                        map: map
                    });
                }

                $( "#trigger-login" ).bind( "click", function() {
                    $('.nav-tabs a[href="#login-form"]').tab('show');
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                // Load custom icon fonts
                WebFontConfig = {
                    google: {
                        families: ['Montserrat::latin', 'Material+Icons']
                    }
                };
                var wf = document.createElement('script');
                wf.src = ('https:' === document.location.protocol ? 'https' : 'http') + '://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
                wf.type = 'text/javascript';
                wf.async = 'true';
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(wf, s);

                // Load deferred videos
                var deferVideo = document.getElementsByTagName('iframe');
                for (var i = 0; i < deferVideo.length; i++) {
                    if (deferVideo[i].getAttribute('data-src')) {
                        deferVideo[i].setAttribute('src', deferVideo[i].getAttribute('data-src'));
                    }
                }
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
